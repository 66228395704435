'use strict';

var adviser = angular.module('adviser', [ 'ngRoute', 'ngResource', 'ngTable', 'ngDialog', 'ngSanitize', 'ui.select', 'ui.bootstrap', 'uiSwitch']);

adviser.directive('messageLength', function($rootScope) {
    return {
        require: 'ngModel',
        link: function(scope, elm, attrs, ctrl) {
            ctrl.$validators.message_length = function(modelValue, viewValue) {
                if (ctrl.$isEmpty(modelValue)) {
                    // consider empty models to be valid
                    return true;
                }

                // для ussd можем не показывать префикс, если сообщение слишком длинное
                var prefix;
                if (scope.interface == 'ussd') {
                    prefix = '';
                } else {
                    prefix = $rootScope.reply_prefix;
                }

                var len = $rootScope.calcLength(prefix + viewValue, scope.interface);
                if (len.currlength <= len.maxlength) {
                    return true;
                }

                // it is invalid
                return false;
            };
        }
    };
});

adviser.directive('requireGroup', function() {
    return {
        require: 'ngModel',
        link: function postLink(scope, element, attrs, ngModel) {
            ngModel.$validators.required = function (value) {
                return !ngModel.$isEmpty(value);
            };
        }
    };
});

adviser
    .config(function($routeProvider, $locationProvider, $httpProvider) {

        $routeProvider.when('/', {
            templateUrl : 'js/templates/templates.html',
            controller : 'root'
        }).when('/home', {
            templateUrl : 'js/templates/home.html',
            controller : 'home'
        }).when('/templates/:interface', {
            templateUrl : 'js/templates/templates.html',
            controller : 'templates'
        }).when('/login', {
            templateUrl : 'js/templates/login.html',
            controller : 'login'
        }).when('/wait', {
            templateUrl : 'js/templates/wait.html'
        }).otherwise('/');

        $httpProvider.defaults.headers.common["X-Requested-With"] = 'XMLHttpRequest';

        $httpProvider.interceptors.push(function($location, $q) {
            return {
                'responseError': function(response) {
                    if (response.status == 401) {
                        $location.path("/login");
                    }

                    return $q.reject(response);
                }
            };
        });

        var requestCounter = 0;
        $httpProvider.interceptors.push(function($q) {
            return {
                'request': function(request) {
                    if (!request.ignore_nprogress) {
                        if (requestCounter++ == 0) {
                            NProgress.start();
                        }
                    }
                    return request;
                },

                'response': function(response) {
                    if (!response.config.ignore_nprogress) {
                        if (--requestCounter == 0) {
                            NProgress.done();
                        }
                    }
                    return response;
                },

                'responseError': function(response) {
                    if (!response.config.ignore_nprogress) {
                        if (--requestCounter == 0) {
                            NProgress.done();
                        }
                    }
                    return $q.reject(response);
                }
            };
        });
    })
    .filter('propsFilter', function() {
        return function(items, props) {
            var out = [];

            if (angular.isArray(items)) {
                var keys = Object.keys(props);

                items.forEach(function(item) {
                    var itemMatches = false;

                    for (var i = 0; i < keys.length; i++) {
                        var prop = keys[i];
                        var text = props[prop].toLowerCase();
                        if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                            itemMatches = true;
                            break;
                        }
                    }

                    if (itemMatches) {
                        out.push(item);
                    }
                });
            } else {
                // Let the output be the input untouched
                out = items;
            }

            return out;
        };
    })
    .run(function ($rootScope, ngDialog) {
        $rootScope.calcLength = function(text, intf) {
            if (!text) {
                return {};
            }

            var rforeign = /[^\u0000-\u007f]/;
            var nonlatin = rforeign.test(text);

            var length = text.length;

            switch (intf) {
                case 'sms':
                    var isconcat = nonlatin ? length > 70 : length > 160;
                    var chunk = (nonlatin ? 67 : 153);

                    return {
                        currlength: length,
                        messagecount: isconcat ? Math.ceil(length / chunk) : 1,
                        maxlength: 3 * chunk,
                        maxmessagecount: 3
                    };
                case 'ussd':
                    return {
                        currlength: length,
                        messagecount: 1,
                        maxlength: nonlatin ? 80 : 182,
                        maxmessagecount: 1
                    }
            }
        };

        $rootScope.showHttpErrorDialog = function(error) {
            var message = "Извините, произошла ошибка: ";
            if (error.status <= 0) {
                message += "сервер недоступен";
            } else if (error.status <= 401) {
                // игнорируем
                return;
            } else {
                message += "код http " + error.status + (error.data && error.data.message ? ", " + error.data.message : "");
            }

            // ошибка
            ngDialog.open({
                template: 'errorTemplate', data: {
                    title: "Ошибка",
                    message: message
                }
            });
        }
    })
    .directive('smppStat', function() {
        return {
            restrict: "E",
            scope: {
                conn: '=conn'
            },
            templateUrl: "js/templates/stat.html"
        };
    })
    .run(function ($location, AuthService) {
        var path = $location.path();

        $location.path("/wait");

        AuthService.login(null, function (authenticated) {
            if (authenticated) {
                $location.path(path);
            } else {
                $location.path("/login");
            }
        });
    });
