'use strict';

adviser
    .controller('header', function ($scope, $location, AuthService) {
        $scope.isActive = function (viewLocation) {
            return viewLocation === $location.path();
        };

        $scope.logout = function () {
            AuthService.logout(function () {
                $location.path("/login");
            });
        };
    })
    .controller('root', function ($scope, $location, AuthService) {
        AuthService.login($scope.credentials, function (authenticated) {
            if (authenticated) {
                $location.path("/templates/sms");
            } else {
                $location.path("/login");
            }
        });
    })
    .controller('home', function ($scope, $rootScope, $resource, $http, $timeout) {
        var api = $resource("/api/adviser/:intf/", {}, {
            'change': {method: 'POST'}
        });

        $scope.status = api.get();
        $scope.change = function (intf) {
            api.change({intf: intf}, $scope.status[intf], function (data) {
                $scope.status = data;
            }, $rootScope.showHttpErrorDialog);
        };

        // статистика через websocket
        var wsUrl = (window.location.protocol === "https:" ? "wss:" : "ws:") + "//" + window.location.host + "/ws/stat";
        var ws = new WebSocket(wsUrl);

        ws.onmessage = function(message) {
            $scope.$apply(function() {
                $scope.stat = JSON.parse(message.data);
            });
        };

        ws.onclose = function () {
            $scope.$apply(function() {
                $scope.stat = {};
            });
        };

        ws.onerror = function () {
            if (ws.readyState) {
                ws.close();
            }

            $scope.$apply(function() {
                $scope.stat = {};
            });
        };

        // при уходе в другой контроллер закроем ws
        $scope.$on("$destroy", function() {
            ws && ws.close();
        });
    })
    .controller('templates', function ($scope, $rootScope, $routeParams, $resource, NgTableParams, ngDialog, $q, $filter) {
        $scope.newid = 1;
        $scope.interface = $routeParams.interface || 'sms';

        var originalData;
        var api = $resource("/api/template/:intf/:id", {intf: $scope.interface}, {
            'create': {method: 'POST'},
            'update': {method: 'PUT'},
            'remove': {method: 'DELETE'}
        });

        $scope.tableParams = new NgTableParams({
            count: 25,
            sorting: { id: "asc" }
        }, {
            counts: [],
            dataset: []
        });

        function resetRow(row, rowForm) {
            row.isEditing = false;
            rowForm.$setPristine();

            return _.find(originalData, function (r) {
                return r.id === row.id;
            });
        }

        $scope.dateOptions = {
            'year-format': "'yy'",
            'starting-day': 1
        };

        $scope.validUntilOpen = function ($event, row) {
            row.validUntil_opened = true;
        };


        $scope.add = function () {
            $scope.isAdding = true;
            $scope.tableParams.settings().dataset.unshift({
                newid: $scope.newid++,
                intf: $scope.interface.toUpperCase(),
                isEditing: true,
                isNew: true
            });
            // we need to ensure the user sees the new row we've just added.
            // it seems a poor but reliable choice to remove sorting and move them to the first page
            // where we know that our new item was added to
            $scope.tableParams.sorting({});
            $scope.tableParams.page(1);
            $scope.tableParams.reload();
        };

        $scope.formatRegions = function(r) {
            return r.map( function (r) {
                return r.name;
            }).join();
        };

        $scope.save = function (row, rowForm) {
            var formatLocalDate = function(date) {
                function pad(number) {
                    return number < 10 ? '0' + number : number;
                }

                return !date ? null : date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate());
            };

            var obj = {
                id: row.id,
                intf: row.intf,
                number: row.number,
                priority: row.priority,
                regions: row.regions,
                deniedRegions: row.deniedRegions,
                service: row.service,
                text: row.text,
                validUntil: formatLocalDate(row.validUntil),
                group: row.group,
                serviceNumber: row.serviceNumber,
                refType: row.refType,
                refId: row.refId,
                subAnswer: row.subAnswer
            };

            (row.isNew ? api.create(obj) : api.update(obj)).$promise.then(function (data) {
                row.lastUpdate = new Date(Date.parse(data.lastUpdate));

                // успешно сохранили
                if (row.isNew) {
                    row.id = data.id;
                    resetRow(row, rowForm);
                    originalData.unshift(angular.copy(row));
                } else {
                    var originalRow = resetRow(row, rowForm);
                    angular.extend(originalRow, row);
                }
            }, $rootScope.showHttpErrorDialog);
        };

        $scope.cancel = function (row, rowForm) {
            if (row.isNew) {
                resetRow(row, rowForm);

                $scope.tableParams.settings().dataset = _.reject($scope.tableParams.settings().dataset, function (item) {
                    return row.newid === item.newid;
                });
                $scope.tableParams.reload();
            } else {
                var originalRow = resetRow(row, rowForm);
                angular.extend(row, originalRow);
            }
        };

        $scope.del = function (row, rowForm) {
            ngDialog.openConfirm({
                template: 'confirmTemplate', data: { message: 'Подтвердите удаление шаблона: "' + row.text + '"' }
            }).then(function (confirm) {
                // пользователь подтвердил, удаляем
                api.delete({ id: row.id }).$promise.then(
                    function (data) {
                        // успешно удалили на сервере, удалим из локальной таблицы
                        $scope.tableParams.settings().dataset = _.reject($scope.tableParams.settings().dataset, function (item) {
                            return row.id === item.id;
                        });
                        $scope.tableParams.reload().then(function (data) {
                            if (data.length === 0 && $scope.tableParams.total() > 0) {
                                $scope.tableParams.page($scope.tableParams.page() - 1);
                                $scope.tableParams.reload();
                            }
                        });
                    }, $rootScope.showHttpErrorDialog
                );
            });
        };

        $scope.groups_filter = $q.defer();
        $scope.update_groups_filter = function(data) {
            $scope.groups_filter.resolve(_.map(data, function (x) { return {id: x.code, title: x.name}}))
        };

        $scope.region_filter = $q.defer();
        $scope.update_regions_filter = function(data) {
            $scope.region_filter.resolve(_.map(data, function (x) { return {id: x.id, title: x.name}}))
        };

        // таблица с шаблонами
        api.get(function (resp) {
            $rootScope.reply_prefix = resp.reply_prefix;

            $scope.regions = resp.regions.sort(function compare(a, b) {
                if (a.name < b.name) {
                    return -1;
                } else if (a.name > b.name) {
                    return 1;
                } else {
                    return 0;
                }
            });
            $scope.update_regions_filter($scope.regions);

            $scope.groups = resp.groups;
            $scope.update_groups_filter($scope.groups);

            var templates = resp.templates;
            for (var key in templates) {
                if (!templates.hasOwnProperty(key)) continue;

                var validUntil = templates[key].validUntil;
                if (validUntil) {
                    templates[key].validUntil = new Date(Date.parse(validUntil));
                }

                var lastUpdate = templates[key].lastUpdate;
                if (lastUpdate) {
                    templates[key].lastUpdate = new Date(Date.parse(lastUpdate));
                }
            }

            $scope.tableParams.settings({
                dataset: templates,
                filterOptions: { filterFn: function (data, filterValues, comparator, anyPropertyKey) {
                    var filteredData = data;
                    for (var property in filterValues) {
                        if (filterValues.hasOwnProperty(property)) {
                            var query = {};
                            query[property] = filterValues[property];

                            switch (property) {
                                case 'regions':
                                case 'deniedRegions':
                                    // true - строгое совпадение
                                    filteredData = $filter('filter')(filteredData, query, true, anyPropertyKey);
                                    break;
                                default:
                                    filteredData = $filter('filter')(filteredData, query, comparator, anyPropertyKey);
                            }
                        }
                    }
                    return filteredData;
                }}
            });
            originalData = angular.copy(templates);
        }, $rootScope.showHttpErrorDialog);
    })
    .controller('login', function ($rootScope, $scope, $location, AuthService) {
        $rootScope.authenticated = false;
        $scope.credentials = {};
        $scope.login = function () {
            AuthService.login($scope.credentials, function (authenticated, message) {
                if (authenticated) {
                    $location.path("/");
                    $scope.error = false;
                } else {
                    $location.path("/login");
                    $scope.errormessage = message;
                    $scope.error = true;
                }
            });
        };
    });
