'use strict';

adviser
    .factory('AuthService', function loginService($rootScope, $http, $window) {
        return {
            isAuthenticated: function () {
                return $rootScope.authenticated;
            },

            login: function(credentials, callback) {
                var headers = credentials ? { authorization : "Basic " + btoa(credentials.login + ":" + credentials.password) } : {};

                $http.get('/api/account', {headers : headers}).then(function(resp) {
                    if (resp.data.name) {
                        $rootScope.authenticated = true;
                    } else {
                        $rootScope.authenticated = false;
                        var message = 'Неправильное имя или пароль';
                    }
                    callback && callback($rootScope.authenticated, message);
                }, function(resp) {
                    var message = 'Произошла ошибка аутентификации. Повторите запрос позже.';
                    if (resp.status = 401) {
                        message = 'Неправильное имя или пароль';
                    }
                    $rootScope.authenticated = false;
                    callback && callback(false, message);
                });
            },

            logout: function(callback) {
                // logout from the server
                $http.post('/api/logout').success(function() {
                    $rootScope.authenticated = false;
                    callback && callback();
                }).error(function(data) {
                    $rootScope.authenticated = false;
                    callback && callback();
                });
            }
        };
    });
